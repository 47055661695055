<template>
  <div class="test">
    <vue-google-autocomplete
      id="map"
      classname="form-control"
      placeholder="Start typing"
      country="pk"
      v-model="this.addressPassed"
      :types="['address']"
      :fields="[
        'geometry',
        'address_components',
        'place_id',
        'formatted_address',
        'name',
      ]"
      v-on:placechanged="selectedOption"
    >
    </vue-google-autocomplete>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  props: {
    addressPassed: String,
  },
  components: {
    "vue-google-autocomplete": VueGoogleAutocomplete,
  },
  data() {
    return {
      selected: null,
      address: null,
      loaded: false,
      options: {
        apiKey: process.env.VUE_APP_GEOCODE_KEY,
        deepSearch: true,
        cors: false,
        focus: false,
        params: {
          language: "en",
          components: "country:pk",
        },
      },
    };
  },
  mounted() {
    console.log("check vue-google-autcomplete");
    console.log(this.addressPassed);
  },

  methods: {
    scriptLoaded() {
      console.log("here");
      // this.MapsInit(); // will declare it in methods
      setTimeout(() => {
        this.loaded = true;
      }, 500);
    },
    selectedOption(place, e) {
      console.log("selectedOption | place", place);
      console.log("selectedOption | e", e);
      this.selected = e;
      const { latitude: lat, longitude: lng } = place;
      this.address = {
        address: `${e.name}, ${e.formatted_address}`,
        coordinates: [lng, lat],
      };
      this.$emit("selected", {
        address: this.address,
      });
    },
  },
};
</script>
<style lang="scss">
.pac-container {
  z-index: 1100;
}
</style>
