<template>
  <div>
    <b-modal
      id="new-rider-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="new-rider-modal"
    >
      <span class="close-btn shadow-sm" @click="closeModal">
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Baskytor</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit.prevent="createNewRider">
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="rider-iamge-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Baskytor Image</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="riderImage"
                id="rider-iamge-input"
                class="mt-3"
                ref="riderImageRef"
                required
              />
            </div>
          </div>
          <div
            class="col-md-6 d-flex justify-content-end upload-image-preview align-items-center"
          >
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="Level image" />
            </template>
            <template v-else>
              <h6>No image added</h6>
            </template>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6 py-2">
            <label for="form-name">Name</label>
            <b-form-input
              id="form-name"
              required
              v-model="form.name"
              placeholder="Name"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-phone">Contact#</label>
            <vue-tel-input
              disabledFetchingCountry
              :onlyCountries="['us']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="10"
              required
              defaultCountry="US"
              v-model="phone"
            ></vue-tel-input>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-address">Address</label>
            <ValidateAddress
              :addressPassed="form.address"
              @sendAddress="recieveAddress"
              @error="validateAddressError"
              ref="validateRef"
            />
          </div>
          <div class="col-md-6 py-2">
            <label for="form-email">Email</label>
            <b-form-input
              required
              v-model="form.email"
              placeholder="xyz@abc.com"
              type="email"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-ssn">DOB</label>
            <b-form-input
              required
              v-model="form.dob"
              placeholder="yyyy-mm-dd"
              pattern="\b\d{4}[-]\d{2}[-]\d{2}\b"
              type="text"
              @keyup="dobInput"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-ssn">Tax Id</label>
            <b-form-input
              required
              v-model="form.ssn"
              type="text"
              pattern="\b\d{3}[-]\d{2}[-]\d{4}\b"
              placeholder="Tax Id (123-12-1234)"
              @keyup="ssnInput"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-bank-name">Starting Level</label>
            <b-form-select
              required
              v-model="form.level"
              :options="levels"
            ></b-form-select>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-bank-name">Bank</label>
            <b-form-select
              required
              v-model="bankName"
              :options="bankOptions"
              @change="changeBank"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2" v-if="bankName === 'Other'">
            <label for="form-account-title">Other Bank Name</label>
            <b-form-input
              required
              v-model="form.bankName"
              placeholder="Bank Name"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-account-title">Account Title</label>
            <b-form-input
              required
              v-model="form.accountTitle"
              placeholder="Bank Account Title"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-account-number">Account Number</label>
            <b-form-input
              required
              v-model="form.accountNumber"
              placeholder="Bank Account Number"
              type="number"
              :min="10000"
              :max="99999999999999999999"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-routing-number">Routing Number</label>
            <b-form-input
              required
              v-model="form.routingNumber"
              type="number"
              placeholder="Routing Number"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-automobile-name">Automobile Make</label>
            <b-form-select
              required
              v-model="form.autoMobileName"
              :options="autoManufacturers"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-automobile-model">Automobile Model</label>
            <b-form-input
              required
              v-model="form.autoMobileModel"
              placeholder="Auto Mobile Model"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-automobile-make">Auto Year</label>
            <b-form-select
              v-model="form.autoMobileMake"
              :options="autoMobileYearOptions"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-automobile-number"
              >Automobile License Plate Number</label
            >
            <b-form-input
              required
              v-model="form.autoMobileNumber"
              placeholder="Automobile License Plate Number"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversLicenseFront" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversLicenseFront !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's License Front</span>
              </label>
              <input
                @change="
                  uploadAttach('driversLicenseFrontRef', 'driversLicenseFront')
                "
                type="file"
                name="driversLicenseFront"
                id="driversLicenseFront"
                class="mt-3"
                ref="driversLicenseFrontRef"
                required
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversLicenseBack" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversLicenseBack !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's License Back</span>
              </label>
              <input
                @change="
                  uploadAttach('driversLicenseBackRef', 'driversLicenseBack')
                "
                type="file"
                name="driversLicenseBack"
                id="driversLicenseBack"
                class="mt-3"
                ref="driversLicenseBackRef"
                required
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="w9TaxForm" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.w9TaxForm !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">W9 Tax Form</span>
              </label>
              <input
                @change="uploadAttach('w9TaxFormRef', 'w9TaxForm')"
                type="file"
                name="w9TaxForm"
                id="w9TaxForm"
                class="mt-3"
                ref="w9TaxFormRef"
                required
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="carInsuranceVerification" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.carInsuranceVerification !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Insurance Card</span>
              </label>
              <input
                @change="
                  uploadAttach(
                    'carInsuranceVerificationRef',
                    'carInsuranceVerification'
                  )
                "
                type="file"
                name="carInsuranceVerification"
                id="carInsuranceVerification"
                class="mt-3"
                ref="carInsuranceVerificationRef"
                required
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="insulatedBag" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.insulatedBag !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Insulated Bag</span>
              </label>
              <input
                @change="uploadAttach('insulatedBagRef', 'insulatedBag')"
                type="file"
                name="insulatedBag"
                id="insulatedBag"
                class="mt-3"
                ref="insulatedBagRef"
                required
              />
            </div>
          </div>

          <div class="col-md-6 py-4">
            <b-form-checkbox
              id="consent-background"
              v-model="form.consentToBackground"
              name="consentToBackground"
              :value="true"
              :unchecked-value="false"
            >
              Baskytor agrees to background check
            </b-form-checkbox>
          </div>
          <div class="col-md-6 py-4">
            <b-form-checkbox
              id="baskytor-agreement"
              v-model="form.documents.baskytorAgreement"
              name="baskytorAgreement"
              :value="true"
              :unchecked-value="false"
            >
              Baskytor agrees to Baskytor agreement
            </b-form-checkbox>
          </div>
        </div>

        <div class="row d-flex justify-content-end p-4 align-items-center">
          <span class="process-status"> {{ processingStatusText }}</span>
          <b-button
            variant="danger"
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import qs from "query-string";
import axios from "axios";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import ValidateAddress from "@/view/pages/riders/ValidateAddress";
import { GET_ALL_LEVELS } from "@/core/services/store/levels.module";

export default {
  name: "NewRiderComponent",
  components: {
    ValidateAddress,
  },
  data() {
    return {
      form: {
        routingNumber: "",
        dob: "",
        name: "",
        phone: "",
        address: "",
        ssn: "",
        level: null,
        accountTitle: "",
        accountNumber: "",
        bankName: "",
        userType: "RIDER",
        zipCode: [],
        autoMobileModel: "",
        autoMobileName: "Abarth",
        autoMobileNumber: "",
        autoMobileMake: "2020",
        email: "",
        consentToBackground: false,
        documents: {
          baskytorAgreement: false,
        },
      },
      documents: {
        driversLicenseFront: null,
        driversLicenseBack: null,
        w9TaxForm: null,
        insulatedBag: null,
        carInsuranceVerification: null,
      },
      bankName: "JPMorgan Chase & Co.",
      phone: "",
      imageUrl: null,
      autoMobileYearOptions: [],
      riderImage: null,

      riderOptions: [
        { text: "Bag", value: "bag" },
        { text: "Jacket", value: "jacket" },
        { text: "Shirt", value: "shirt" },
        { text: "Raincoat", value: "raincoat" },
      ],
      uploadAttachError: false,
      processingRequest: "",
      hasError: false,
      processingError: "",
      processingStatusText: "",
      /* stripe vars */
      city: "",
      country: "",
      state: "",
      line1: "",
      line2: "",
      autoManufacturers: [
        "Abarth",
        "Alfa Romeo",
        "Aston Martin",
        "Audi",
        "Bentley",
        "BMW",
        "Bugatti",
        "Cadillac",
        "Chevrolet",
        "Chrysler",
        "Citroën",
        "Dacia",
        "Daewoo",
        "Daihatsu",
        "Dodge",
        "Donkervoort",
        "DS",
        "Ferrari",
        "Fiat",
        "Fisker",
        "Ford",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Iveco",
        "Jaguar",
        "Jeep",
        "Kia",
        "KTM",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Landwind",
        "Lexus",
        "Lotus",
        "Maserati",
        "Maybach",
        "Mazda",
        "McLaren",
        "Mercedes-Benz",
        "MG",
        "Mini",
        "Mitsubishi",
        "Morgan",
        "Nissan",
        "Opel",
        "Peugeot",
        "Porsche",
        "Renault",
        "Rolls-Royce",
        "Rover",
        "Saab",
        "Seat",
        "Skoda",
        "Smart",
        "SsangYong",
        "Subaru",
        "Suzuki",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      bankOptions: [
        "JPMorgan Chase & Co.",
        "Bank of America Corp.",
        "Wells Fargo & Co.",
        "Citigroup Inc.",
        "U.S. Bancorp",
        "Truist Bank",
        "PNC Financial Services Group Inc.",
        "TD Group US Holdings LLC",
        "Capital One Financial Corp.",
        "Bank of New York Mellon Corp.",
        "Goldman Sachs Group Inc.",
        "State Street Corp.",
        "HSBC",
        "Fifth Third Bank",
        "Citizens Financial Group",
        "Other",
      ],
    };
  },
  computed: {
    ...mapState({
      levels: ({ levels }) => {
        const options = levels.allLevels.map((it) => ({
          text: it.name,
          value: it._id,
        }));
        options.unshift({ text: "No Level Selected", value: null });
        return options;
      },
    }),
  },
  methods: {
    showModal() {
      this.$bvModal.show("new-rider-modal");
    },
    closeModal() {
      this.$bvModal.hide("new-rider-modal");
      this.$emit("closedModal");
    },
    uploadFile() {
      this.riderImage = this.$refs.riderImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.riderImage);
    },
    uploadAttach(ref, field) {
      this.documents[field] = this.$refs[ref].files[0];
    },
    changeBank(val) {
      this.form.bankName = val;
    },
    dobInput(evt) {
      // Only ASCII character in that range allowed
      let { dob } = this.form;
      dob = dob.split("-").join("");
      var ASCIICode = evt.which ? evt.which : evt.keyCode;
      if (dob.length > 0) {
        if (
          ASCIICode !== 189 &&
          ASCIICode > 31 &&
          (ASCIICode < 48 ||
            (ASCIICode > 57 && ASCIICode < 96) ||
            ASCIICode > 105)
        ) {
          dob = dob.slice(0, dob.length - 1);
        }
        let first = "";
        let second = "";
        let third = "";
        let string = dob;
        if (dob.length > 4) {
          first = dob.slice(0, 4);
          string = `${first}-`;
          if (dob.length > 6) {
            second = dob.slice(4, 6);
            string = `${string}${second}-`;
            if (dob.length > 8) {
              third = dob.slice(6, 8);
              string = `${string}${third}`;
            } else {
              third = dob.slice(6);
              string = `${string}${third}`;
            }
          } else {
            second = dob.slice(4);
            string = `${string}${second}`;
          }
        }
        this.form.dob = string;
      }
      return true;
    },
    ssnInput(evt) {
      // Only ASCII character in that range allowed
      let { ssn } = this.form;
      ssn = ssn.split("-").join("");
      var ASCIICode = evt.which ? evt.which : evt.keyCode;
      if (ssn.length > 0) {
        if (
          ASCIICode !== 189 &&
          ASCIICode > 31 &&
          (ASCIICode < 48 ||
            (ASCIICode > 57 && ASCIICode < 96) ||
            ASCIICode > 105)
        ) {
          ssn = ssn.slice(0, ssn.length - 1);
        }
        let first = "";
        let second = "";
        let third = "";
        let string = ssn;
        if (ssn.length > 3) {
          first = ssn.slice(0, 3);
          string = `${first}-`;
          if (ssn.length > 4) {
            second = ssn.slice(3, 5);
            string = `${string}${second}-`;
            if (ssn.length > 6) {
              third = ssn.slice(5, 9);
              string = `${string}${third}`;
            } else {
              third = ssn.slice(5);
              string = `${string}${third}`;
            }
          } else {
            second = ssn.slice(3);
            string = `${string}${second}`;
          }
        }
        this.form.ssn = string;
      }
      return true;
    },
    recieveAddress(obj) {
      this.form.address = obj.address;
      this.form.zipCode = obj.zipCode;
      this.city = obj.city;
      this.country = obj.country;
      this.state = obj.state;
      this.line1 = obj.line1;
      this.line2 = obj.line2;
    },
    validateAddressError(err) {
      this.hasError = "d-flex";
      this.processingError = err;
      this.processingStatusText = this.processingError;
      this.processingRequest = "";
      setTimeout(() => {
        this.hasError = "d-none";
        this.processingError = "";
      }, 3000);
    },
    createNewRider() {
      if (!this.$refs.validateRef.parentSubmittable()) {
        return;
      }
      const { consentToBackground } = this.form;
      let docError = null;

      if (!consentToBackground) {
        docError = "Consent to background check is required.";
      }
      if (docError !== null) {
        this.hasError = "d-flex";
        this.processingError = docError;
        setTimeout(() => {
          this.hasError = "d-none";
          this.processingError = "";
        }, 3000);
      }
      if (this) this.createUser();
    },
    createUser() {
      try {
        const tk = JwtService.getToken();
        this.form.token = tk;
        this.processingRequest = "processing";
        this.processingStatusText = "Creating user record ...";
        this.form.phone = "+1" + this.phone;
        this.form.bankName =
          this.form.bankName === "" ? this.bankName : this.form.bankName;
        ApiService.setHeader("content-type", "application/JSON");
        ApiService.post("/user/addUser", this.form)
          .then((res) => {
            res = res.data;
            console.log("createUser | res", res);
            if (res.status === "Duplicate") {
              this.hasError = "d-flex";
              this.processingError = res.data;
              this.processingStatusText = this.processingError;
              this.processingRequest = "";
              setTimeout(() => {
                this.hasError = "d-none";
                this.processingError = "";
              }, 3000);
              return;
            }
            const { user } = res;
            this.uploadImage(user, this.uploadDocuments);
          })
          .catch(({ data }) => {
            this.setError(`New Baskytor: ${data.message} on ${data.url}.`);
          });
      } catch (error) {
        console.log("Console Log: : error", error);
      }
    },
    uploadImage(user, next) {
      if (this.riderImage !== null) {
        const formData = new FormData();
        formData.append("image", this.riderImage);
        formData.append("riderId", user.userDetail);
        this.processingStatusText = "Uploading Baskytor image ...";
        ApiService.post("/rider/uploadImage", formData)
          .then((res1) => {
            if (res1.data.success) {
              next(user, this.createCardholder);
            }
          })
          .catch(({ data }) => {
            this.setError(`Upload Image: ${data.message} on ${data.url}.`);
            ApiService.setHeader("content-type", "application/JSON");
          });
      }
    },
    uploadDocuments(user, next) {
      const newFormData = new FormData();
      Object.entries(this.documents).forEach(([key, value]) => {
        newFormData.append(key, value);
      });
      newFormData.append("riderId", user.userDetail);
      this.processingStatusText = "Uploading Baskytor document ...";
      ApiService.post("/rider/uploadDocument", newFormData)
        .then((res2) => {
          ApiService.setHeader("content-type", "application/JSON");
          if (res2.data.success) {
            ApiService.setHeader("content-type", "application/JSON");
            next(user, this.createCard);
          }
        })
        .catch(({ data }) => {
          this.setError(`Upload Document: ${data.message} on ${data.url}.`);
          ApiService.setHeader("content-type", "application/JSON");
        });
    },
    createCardholder(user, next) {
      this.processingStatusText = "Creating stripe cardholder";
      const cardholderData = {
        type: "individual",
        name: this.form.name,
        email: this.form.email,
        phone_number: this.form.phone,
        "billing[address][line1]": this.line1,
        "billing[address][city]": this.city,
        "billing[address][state]": this.state,
        "billing[address][country]": this.country,
        "billing[address][postal_code]": this.form.zipCode,
      };
      axios
        .post(
          "https://api.stripe.com/v1/issuing/cardholders",
          qs.stringify(cardholderData),
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRIPE_KEY}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(async ({ data: cardholder }) => {
          /* calling this.createCard */
          next(user, cardholder, this.createConnectedAccount);
        })
        .catch((/* err */) => {
          this.setError(
            `Stripe Cardholder: Error while creating stripe cardholder for the user.`
          );
        });
    },
    createCard(user, cardholder, next) {
      this.processingStatusText = "Creating stripe card for the user";
      const card_data = {
        cardholder: cardholder.id,
        currency: "usd",
        type: process.env.VUE_APP_STAGE === "qa" ? "virtual" : "physical",
        "shipping[name]": this.form.name,
        "shipping[address][line1]": this.line1,
        "shipping[address][city]": this.city,
        "shipping[address][state]": this.state,
        "shipping[address][country]": this.country,
        "shipping[address][postal_code]": this.form.zipCode,
      };
      /* Creating a card for the user */
      axios
        .post(
          "https://api.stripe.com/v1/issuing/cards",
          qs.stringify(card_data),
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRIPE_KEY}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(({ data: card }) => {
          /* calling this.createConnectedAccount */
          next(user, cardholder, card, this.updateConnectedAccount);
        })
        .catch((/* err */) => {
          this.setError(
            `Stripe Card: Error while creating stripe card for the user.`
          );
        });
    },
    createConnectedAccount(user, cardholder, card, next) {
      this.processingStatusText = "Creating connected account for the user";
      const { email } = this.form;
      const acc_data = {
        type: "express",
        country: "US",
        email: email,
        "capabilities[card_payments][requested]": true,
        "capabilities[transfers][requested]": true,
      };
      /* Creating a connect account for the user */
      axios
        .post("https://api.stripe.com/v1/accounts", qs.stringify(acc_data), {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_STRIPE_KEY}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(({ data: account }) => {
          /* calling this.updateConnectedAccount */
          next(user, cardholder, card, account, this.updateUser);
        })
        .catch((/* accountError */) => {
          this.setError(
            `Connected Account: Error while creating connected stripe account for the user.`
          );
        });
    },
    updateConnectedAccount(user, cardholder, card, account, next) {
      this.processingStatusText = "Changing business type...";
      /* Updating the user business type from null to company */
      const new_acc_data = {
        business_type: "individual",
      };
      axios
        .post(
          `https://api.stripe.com/v1/accounts/${account.id}`,
          qs.stringify(new_acc_data),
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRIPE_KEY}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(async (/* { data: accountUpdated } */) => {
          const tk = JwtService.getToken();
          const stripeOptions = {
            stripeId: cardholder.id,
            cardId: card.id,
            connectId: account.id,
            token: tk,
            riderDetail: user.userDetail,
          };
          /* calling this.updateUser */
          next(user.password, stripeOptions);
        })
        .catch((/* accountTypeError */) => {
          this.setError(
            `Connected Account Update: Error while setting connected account type to individual.`
          );
        });
    },
    updateUser(pass, stripeOptions) {
      this.processingStatusText = "Linking Stripe to rider ...";
      ApiService.post("/rider/addStripeId", stripeOptions)
        .then((/* res3 */) => {
          this.processingRequest = "";
          this.$emit("addNewRider", pass);
        })
        .catch(({ data }) => {
          this.setError(
            `Adding stripe values to rider: ${data.message} on ${data.url}`
          );
        });
    },
    setError(val) {
      this.hasError = "d-flex";
      this.processingError = val;
      this.processingStatusText = val;
      setTimeout(() => {
        this.processingRequest = "";
        this.hasError = "d-none";
        this.processingError = "";
      }, 5000);
    },
  },
  async mounted() {
    let arr = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1988; i <= currentYear; ++i) {
      arr.push({ text: `${i}`, value: i });
    }
    this.autoMobileYearOptions = arr;
    await this.$store.dispatch(GET_ALL_LEVELS);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .new-rider-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;
    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .upload-image-preview {
    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      span.icon {
        pointer-events: none;
        &.attachment {
          display: flex;
          .text {
            max-width: 100px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        pointer-events: none;
        font-weight: 12px;
        display: flex;
        align-items: center;
        font-weight: 700;
        &.document {
          font-weight: 600;
        }
      }

      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
    }
  }

  .process-status {
    font-size: 12px;
    padding: 0 5px;
  }
  input.form-control,
  select.custom-select {
    height: auto;
    padding: 5px 10px;
  }
  input.vti__control {
    box-shadow: none;
  }
}
</style>
