<template>
  <div class="riders">
    <div class="row">
      <div class="top-row w-100">
        <div
          class="create-new-btn shadow-sm"
          v-if="!activeTab"
          @click="createNewRider"
        >
          Create New
        </div>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Rider
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            Archive
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab active>
        <template v-if="activeTab === 0">
          <ActiveRiders ref="activeRidersRef" @editRider="editRider" />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 1">
          <ArchivedRiders />
        </template>
      </b-tab>
    </b-tabs>
    <template v-if="processNewRider && region === 'US'">
      <new-rider-us
        ref="newRiderModal"
        @addNewRider="addNewRider"
        @closedModal="newRiderReset"
      />
    </template>
    <template v-else-if="processNewRider && region === 'PAKISTAN'">
      <new-rider-pk
        ref="newRiderModal"
        @addNewRider="addNewRider"
        @closedModal="newRiderReset"
      />
    </template>
    <RiderPass
      ref="riderPassModal"
      :riderPass="secretPass"
      @updateRiders="updateRiders"
    />
    <RiderSidebar ref="riderSidebarRef" />
    <template v-if="riderToEdit !== null && region === 'PAKISTAN'">
      <edit-rider-pk
        ref="editRiderModal"
        @updateRiders="updateRiders"
        :riderToEdit="riderToEdit"
        @close="closeEdit"
        @riderEdited="riderEdited"
      />
    </template>
    <template v-else-if="riderToEdit !== null && region === 'US'">
      <edit-rider-us
        ref="editRiderModal"
        @updateRiders="updateRiders"
        :riderToEdit="riderToEdit"
        @close="closeEdit"
        @riderEdited="riderEdited"
      />
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ActiveRiders from "@/view/pages/riders/ActiveRiders";
import ArchivedRiders from "@/view/pages/riders/ArchivedRiders";
import NewRiderUS from "@/view/pages/riders/us/NewRider";
import NewRiderPK from "@/view/pages/riders/pk/NewRider";
import RiderPass from "@/view/pages/riders/RiderPass";
import RiderSidebar from "@/view/pages/riders/RiderSidebar";
import EditRiderPK from "@/view/pages/riders/pk//EditRider";
import EditRiderUS from "@/view/pages/riders/us/EditRider";
export default {
  components: {
    ActiveRiders,
    ArchivedRiders,
    "new-rider-pk": NewRiderPK,
    "new-rider-us": NewRiderUS,
    RiderSidebar,
    RiderPass,
    "edit-rider-pk": EditRiderPK,
    "edit-rider-us": EditRiderUS,
  },
  data() {
    return {
      activeTab: 0,
      secretPass: "",
      riderToEdit: null,
      processNewRider: false,
    };
  },
  computed: {
    ...mapState({
      region: ({ region }) => region.region,
    }),
  },
  methods: {
    changeTab: function (index) {
      this.activeTab = index;
    },
    createNewRider: function () {
      this.processNewRider = true;
      setTimeout(() => {
        this.$refs.newRiderModal.showModal();
      }, 100);
    },
    openSidebar: function () {
      this.$refs.riderSidebarRef.openSidebar();
    },
    newRiderReset() {
      setTimeout(() => {
        this.processNewRider = false;
      }, 100);
    },
    closeEdit() {
      this.$refs.editRiderModal.closeModal();
      setTimeout(() => {
        this.riderToEdit = null;
      }, 100);
    },
    editRider: function (rider) {
      this.riderToEdit = rider;
      setTimeout(() => {
        this.$refs.editRiderModal.showModal();
      }, 100);
    },
    addNewRider: function (text) {
      this.secretPass = text;
      this.$refs.newRiderModal.closeModal();
      this.processNewRider = false;
      this.$refs.riderPassModal.showModal();
    },
    riderEdited: function () {
      this.$refs.activeRidersRef.updateRiders();
      this.$refs.editRiderModal.closeModal();
      setTimeout(() => {
        this.riderToEdit = null;
      }, 100);
    },
    updateRiders: function () {
      this.$refs.activeRidersRef.updateRiders();
      this.secretPass = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.riders {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;
    .create-new-btn {
      position: absolute;
      right: 15px;
      top: 0;
      background: #fff;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 10px;
      font-weight: 700;
    }
    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;
      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
