<template>
  <div>
    <b-modal
      id="edit-rider-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="edit-rider-modal"
    >
      <span class="close-btn shadow-sm" @click="closeModal">
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Edit Baskytor</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit.prevent="sendEditRider">
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="rider-iamge-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Baskytor Image</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="riderImage"
                id="rider-iamge-input"
                class="mt-3"
                ref="riderImageRef"
              />
            </div>
          </div>
          <div
            class="col-md-6 d-flex justify-content-end upload-image-preview align-items-center"
          >
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="Level image" />
            </template>
            <template v-else>
              <h6>No image added</h6>
            </template>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6 py-2">
            <label for="form-name">Name</label>
            <b-form-input
              id="form-name"
              required
              v-model="form.name"
              placeholder="Name"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-phone">Contact#</label>
            <vue-tel-input
              disabledFetchingCountry
              :onlyCountries="['us']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="10"
              required
              defaultCountry="US"
              v-model="phone"
            ></vue-tel-input>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-address">Address</label>
            <ValidateAddress
              :addressPassed="form.address"
              @sendAddress="recieveAddress"
              @error="validateAddressError"
              ref="validateRef"
            />
          </div>
          <div class="col-md-6 py-2">
            <label for="form-email">Email</label>
            <b-form-input
              required
              v-model="form.email"
              placeholder="xyz@abc.com"
              type="email"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-ssn">DOB</label>
            <b-form-input
              required
              v-model="form.dob"
              placeholder="yyyy-mm-dd"
              pattern="\b\d{4}[-]\d{2}[-]\d{2}\b"
              type="text"
              @keyup="dobInput"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-bank-name">Starting Level</label>
            <b-form-select
              required
              v-model="form.level"
              text-field="text"
              value-field="value"
              :options="levels"
            ></b-form-select>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-ssn">Tax Id</label>
            <b-form-input
              required
              v-model="form.ssn"
              type="text"
              @keyup="ssnInput"
              pattern="\b\d{3}[-]\d{2}[-]\d{4}\b"
              placeholder="Tax Id (123-12-1234)"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-bank-name">Bank</label>
            <b-form-select
              required
              v-model="bankName"
              :options="bankOptions"
              @change="changeBank"
            ></b-form-select>
          </div>
          <div class="col-md-6 py-2" v-if="bankName === 'Other'">
            <label for="form-account-title">Other Bank Name</label>
            <b-form-input
              required
              v-model="form.bankName"
              placeholder="Bank Name"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-account-title">Account Title</label>
            <b-form-input
              required
              v-model="form.accountTitle"
              placeholder="Bank Account Title"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-account-number">Account Number</label>
            <b-form-input
              required
              v-model="form.accountNumber"
              placeholder="Bank Account Number"
              type="number"
              :min="1000000000"
              :max="999999999999999999"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-routing-number">Routing Number</label>
            <b-form-input
              required
              v-model="form.routingNumber"
              type="number"
              placeholder="Routing Number"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-automobile-name">Automobile Make</label>
            <b-form-select
              required
              v-model="form.autoMobileName"
              :options="autoManufacturers"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-automobile-model">Automobile Model</label>
            <b-form-input
              required
              v-model="form.autoMobileModel"
              placeholder="Auto Mobile Model"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2">
            <label for="form-automobile-make">Auto Year</label>
            <b-form-select
              v-model="form.autoMobileMake"
              :options="autoMobileYearOptions"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2">
            <label for="form-automobile-number"
              >Automobile License Plate Number</label
            >
            <b-form-input
              required
              v-model="form.autoMobileNumber"
              placeholder="Automobile License Plate Number"
            ></b-form-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversLicenseFront" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversLicenseFront !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's License Front</span>
              </label>
              <input
                @change="
                  uploadAttach('driversLicenseFrontRef', 'driversLicenseFront')
                "
                type="file"
                name="driversLicenseFront"
                id="driversLicenseFront"
                class="mt-3"
                ref="driversLicenseFrontRef"
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversLicenseBack" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversLicenseBack !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's License Back</span>
              </label>
              <input
                @change="
                  uploadAttach('driversLicenseBackRef', 'driversLicenseBack')
                "
                type="file"
                name="driversLicenseBack"
                id="driversLicenseBack"
                class="mt-3"
                ref="driversLicenseBackRef"
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="w9TaxForm" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.w9TaxForm !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">W9 Tax Form</span>
              </label>
              <input
                @change="uploadAttach('w9TaxFormRef', 'w9TaxForm')"
                type="file"
                name="w9TaxForm"
                id="w9TaxForm"
                class="mt-3"
                ref="w9TaxFormRef"
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="carInsuranceVerification" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.carInsuranceVerification !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Insurance Card</span>
              </label>
              <input
                @change="
                  uploadAttach(
                    'carInsuranceVerificationRef',
                    'carInsuranceVerification'
                  )
                "
                type="file"
                name="carInsuranceVerification"
                id="carInsuranceVerification"
                class="mt-3"
                ref="carInsuranceVerificationRef"
              />
            </div>
          </div>
          <div class="col-md-6 py-4">
            <div class="image-upload" :class="uploadAttachError">
              <label for="insulatedBag" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.insulatedBag !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Insulated Bag</span>
              </label>
              <input
                @change="uploadAttach('insulatedBagRef', 'insulatedBag')"
                type="file"
                name="insulatedBag"
                id="insulatedBag"
                class="mt-3"
                ref="insulatedBagRef"
              />
            </div>
          </div>

          <div class="col-md-6 py-4">
            <b-form-checkbox
              id="consent-background"
              v-model="form.consentToBackground"
              name="consentToBackground"
              :value="true"
              :unchecked-value="false"
            >
              Baskytor agrees to background check
            </b-form-checkbox>
          </div>
          <div class="col-md-6 py-4">
            <b-form-checkbox
              id="baskytor-agreement"
              v-model="form.documents.baskytorAgreement"
              name="baskytorAgreement"
              :value="true"
              :unchecked-value="false"
            >
              Baskytor agrees to Baskytor agreement
            </b-form-checkbox>
          </div>
        </div>

        <div class="row d-flex justify-content-end p-4 align-items-center">
          <span class="process-status"> {{ processingStatusText }}</span>
          <b-button
            variant="danger"
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import JwtService from "@/core/services/jwt.service";
import ValidateAddress from "@/view/pages/riders/ValidateAddress";
import { GET_ALL_LEVELS } from "@/core/services/store/levels.module";
import {
  UPDATE_RIDER_INFO,
  UPDATE_RIDER_IMAGE,
  UPDATE_RIDER_DOCUMENTS,
} from "@/core/services/store/riders.module";
export default {
  props: {
    riderToEdit: Object,
  },
  components: {
    ValidateAddress,
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        address: "",
        ssn: "",
        accountTitle: "",
        accountNumber: "",
        bankName: "Other",
        userType: "RIDER",
        zipCode: "",
        autoMobileModel: "",
        autoMobileName: "",
        autoMobileNumber: "",
        autoMobileMake: "2020",
        docId: "",
        dob: "",
        consentToBackground: false,
        level: null,
        documents: {
          driversLicenseFront: false,
          driversLicenseBack: false,
          w9TaxForm: false,
          baskytorAgreement: false,
          carInsuranceVerification: false,
          insulatedBag: false,
        },
      },
      documents: [],
      bankName: "",
      processingStatusText: "",
      validateAddressError: false,
      autoMobileYearOptions: [],
      riderImage: null,
      imageUrl: null,
      phone: "",
      riderOptions: [
        { text: "Bag", value: "bag" },
        { text: "Jacket", value: "jacket" },
        { text: "Shirt", value: "shirt" },
        { text: "Raincoat", value: "raincoat" },
      ],
      uploadAttachError: false,
      processingRequest: "",
      hasError: false,
      processingError: "",
      autoManufacturers: [
        "Abarth",
        "Alfa Romeo",
        "Aston Martin",
        "Audi",
        "Bentley",
        "BMW",
        "Bugatti",
        "Cadillac",
        "Chevrolet",
        "Chrysler",
        "Citroën",
        "Dacia",
        "Daewoo",
        "Daihatsu",
        "Dodge",
        "Donkervoort",
        "DS",
        "Ferrari",
        "Fiat",
        "Fisker",
        "Ford",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Iveco",
        "Jaguar",
        "Jeep",
        "Kia",
        "KTM",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Landwind",
        "Lexus",
        "Lotus",
        "Maserati",
        "Maybach",
        "Mazda",
        "McLaren",
        "Mercedes-Benz",
        "MG",
        "Mini",
        "Mitsubishi",
        "Morgan",
        "Nissan",
        "Opel",
        "Peugeot",
        "Porsche",
        "Renault",
        "Rolls-Royce",
        "Rover",
        "Saab",
        "Seat",
        "Skoda",
        "Smart",
        "SsangYong",
        "Subaru",
        "Suzuki",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      bankOptions: [
        "JPMorgan Chase & Co.",
        "Bank of America Corp.",
        "Wells Fargo & Co.",
        "Citigroup Inc.",
        "U.S. Bancorp",
        "Truist Bank",
        "PNC Financial Services Group Inc.",
        "TD Group US Holdings LLC",
        "Capital One Financial Corp.",
        "Bank of New York Mellon Corp.",
        "Goldman Sachs Group Inc.",
        "State Street Corp.",
        "HSBC",
        "Fifth Third Bank",
        "Citizens Financial Group",
        "Other",
      ],
    };
  },
  computed: {
    ...mapState({
      levels: ({ levels }) => {
        const options = levels.allLevels.map((it) => ({
          text: it.name,
          value: it._id,
        }));
        options.unshift({ text: "No Level Selected", value: null });
        return options;
      },
    }),
  },
  methods: {
    showModal: function () {
      this.$bvModal.show("edit-rider-modal");
    },
    closeModal: function () {
      this.$emit("close");
    },
    hide: function () {
      this.$bvModal.hide("edit-rider-modal");
    },
    uploadFile: function () {
      this.riderImage = this.$refs.riderImageRef.files[0];
    },
    uploadAttach(ref, field) {
      this.documents[field] = this.$refs[ref].files[0];
    },
    resetForm: function () {
      this.$emit("resetEditRider");
    },
    recieveAddress: function (obj) {
      this.form.address = obj.address;
      this.form.zipCode = obj.zipCode;
      this.city = obj.city;
      this.country = obj.country;
      this.state = obj.state;
      this.line1 = obj.line1;
      this.line2 = obj.line2;
    },
    sendEditRider: async function (e) {
      e.preventDefault();
      this.form.bankName =
        this.form.bankName === "" ? this.bankName : this.form.bankName;
      const tk = JwtService.getToken();
      this.form.token = tk;
      this.processingRequest = "processing";
      if (!this.phone.includes("+1")) this.form.phone = "+1" + this.phone;
      let error = await this.$store.dispatch(UPDATE_RIDER_INFO, this.form);

      if (error !== null) {
        this.hasError = "d-flex";
        this.processingError = error;
        this.processingRequest = "";
        setTimeout(() => {
          this.hasError = "d-none";
          this.processingError = "";
        }, 3000);
        return;
      }
      if (this.riderImage) {
        const formData = new FormData();
        formData.append("image", this.riderImage);
        formData.append("riderId", this.riderToEdit._id);
        error = await this.$store.dispatch(UPDATE_RIDER_IMAGE, formData);
        if (error !== null) {
          this.hasError = "d-flex";
          this.processingError = error;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
          return;
        }
      }
      const newDocs = Object.entries(this.documents).reduce((acc, doc) => {
        const type = typeof doc[1];
        if (type !== "string" && type !== "boolean") {
          acc[doc[0]] = doc[1];
        }
        return acc;
      }, {});

      console.log(
        "file: EditRider.vue | line 607 | newDocs | newDocs",
        newDocs
      );
      if (Object.keys(newDocs).length > 0) {
        const newFormData = new FormData();
        Object.entries(newDocs).forEach(([key, value]) => {
          newFormData.append(key, value);
        });
        newFormData.append("riderId", this.riderToEdit._id);
        error = await this.$store.dispatch(UPDATE_RIDER_DOCUMENTS, newFormData);
        if (error !== null) {
          this.hasError = "d-flex";
          this.processingError = error;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
        }
      }
      this.processingRequest = "";
      this.$emit("riderEdited");
    },
    changeBank(val) {
      this.form.bankName = val;
    },
    dobInput(evt) {
      // Only ASCII character in that range allowed
      let { dob } = this.form;
      dob = dob.split("-").join("");
      var ASCIICode = evt.which ? evt.which : evt.keyCode;
      if (dob.length > 0) {
        if (
          ASCIICode !== 189 &&
          ASCIICode > 31 &&
          (ASCIICode < 48 || ASCIICode > 57)
        ) {
          dob = dob.slice(0, dob.length - 1);
        }
        let first = "";
        let second = "";
        let third = "";
        let string = dob;
        if (dob.length > 4) {
          first = dob.slice(0, 4);
          string = `${first}-`;
          if (dob.length > 6) {
            second = dob.slice(4, 6);
            string = `${string}${second}-`;
            if (dob.length > 8) {
              third = dob.slice(6, 8);
              string = `${string}${third}`;
            } else {
              third = dob.slice(6);
              string = `${string}${third}`;
            }
          } else {
            second = dob.slice(4);
            string = `${string}${second}`;
          }
        }
        this.form.dob = string;
      }
      return true;
    },
    ssnInput(evt) {
      // Only ASCII character in that range allowed
      let { ssn } = this.form;
      ssn = ssn.split("-").join("");
      var ASCIICode = evt.which ? evt.which : evt.keyCode;
      if (ssn.length > 0) {
        if (
          ASCIICode !== 189 &&
          ASCIICode > 31 &&
          (ASCIICode < 48 ||
            (ASCIICode > 57 && ASCIICode < 96) ||
            ASCIICode > 105)
        ) {
          ssn = ssn.slice(0, ssn.length - 1);
        }
        let first = "";
        let second = "";
        let third = "";
        let string = ssn;
        if (ssn.length > 3) {
          first = ssn.slice(0, 3);
          string = `${first}-`;
          if (ssn.length > 4) {
            second = ssn.slice(3, 5);
            string = `${string}${second}-`;
            if (ssn.length > 6) {
              third = ssn.slice(5, 9);
              string = `${string}${third}`;
            } else {
              third = ssn.slice(5);
              string = `${string}${third}`;
            }
          } else {
            second = ssn.slice(3);
            string = `${string}${second}`;
          }
        }
        this.form.ssn = string;
      }
      return true;
    },
  },
  async mounted() {
    let arr = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1988; i <= currentYear; ++i) {
      arr.push({ text: `${i}`, value: i });
    }
    this.autoMobileYearOptions = arr;
    const val = this.riderToEdit;
    this.form.name = val.name;
    this.form.email = val.email;
    let tempPhone = val.phone;
    this.phone = tempPhone.replace("+1", "");
    this.form.address = val.address;
    this.form.routingNumber = val.routingNumber;
    this.form.ssn = val.ssn;
    this.form.dob = val.dob || "";
    this.form.accountTitle = val.accountTitle;
    this.form.accountNumber = val.accountNumber;
    this.form.bankName = val.bankName;
    this.form.userType = "RIDER";
    this.form.stripeCardNumber = val.stripeCardNumber;
    this.form.zipCode = val.zipCode;
    this.form.autoMobileModel = val.autoMobileModel;
    this.form.autoMobileName = val.autoMobileName;
    this.form.autoMobileNumber = val.autoMobileNumber;
    this.form.autoMobileMake = val.autoMobileMake;
    this.form.consentToBackground = val.consentToBackground;
    this.form.docId = val.docId;
    this.form.userId = val.userId;
    this.form.riderId = val._id;
    this.bankName = val.bankName;
    if (!this.bankOptions.includes(val.bankName)) {
      this.bankName = "Other";
    }
    this.imageUrl = val.profileImage;
    Object.entries(val.documents).forEach(([key, val]) => {
      this.form.documents[key] = val;
    });
    this.form.level = val.level;
    this.form.bankName = val.bankName;
    this.documents = this.form.documents;
    await this.$store.dispatch(GET_ALL_LEVELS);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .edit-rider-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;
    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .upload-image-preview {
    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      span.icon {
        pointer-events: none;
        &.attachment {
          display: flex;
          .text {
            max-width: 100px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        pointer-events: none;
        font-weight: 12px;
        display: flex;
        align-items: center;
        font-weight: 700;
        &.document {
          font-weight: 600;
        }
      }

      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
    }
  }

  .process-status {
    font-size: 12px;
    padding: 0 5px;
  }
  input.form-control,
  select.custom-select {
    height: auto;
    padding: 5px 10px;
  }
  input.vti__control {
    box-shadow: none;
  }
}
</style>
